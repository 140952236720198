import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class RouteWithSubRoutes extends Component{
  render(){
    return(
      <Route
        path={this.props.path}
        exact={this.props.route.exact}
        render={props => (
          // pass the sub-routes down to keep nesting
          <this.props.route.component {...props}
            routes={this.props.route.routes}
            name={this.props.route.name}
            content={this.props.route.content}/>
        )}
      />
    )
  }
}
