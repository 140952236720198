import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class Iletisim extends Component{
  render(){
    return(
      <div className="Icerik-Content">
        <h2>İletişim</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d2221.5447981897896!2d30.778166203836893!3d36.909698640484805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x14c384ed030a8b35%3A0xec07fa0f96a0ab3e!2sYenig%C3%B6l+Mahallesi%2C+07200+Muratpa%C5%9Fa%2FAntalya!3m2!1d36.8973618!2d30.778094799999998!5e1!3m2!1str!2str!4v1542450012713"
          allowfullscreen>
        </iframe>
        <div className="Iletisim-wrapper">
          <input placeholder="İsim"/>
          <input placeholder="Soyisim"/>
          <input placeholder="E-mail"/>
          <textarea placeholder="Mesaj"/>
          <button>Gönder</button>
        </div>
      </div>
    )
  }
}
