import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class Widget extends Component{
  render(){
    return(
      <div className="Widget-wrapper">
        <ul>
          <li><Link to="/hizmetlerimiz/fuarlojistigi">FUAR LOJİSTİĞİ HAKKINDA</Link></li>
          <li><Link to="/referanslarimiz">REFERANSLARIMIZ</Link></li>
          <li><Link to="/hizmetlerimiz">HİZMETLERİMİZ</Link></li>
        </ul>
        <div className="detay-wrapper">
        <p>DETAY ÖNEMLİDİR!</p>
        <span>
          Emir Cargo; en ufak paketten en büyük yüke kadar her bir parça eşyanızın fuar için aynı değerde olduğunu, belirtilen tarih ve saatte eşyanızın hasarsız ve etkisiz olarak standınıza teslim edilmesi gerektiğinin önemini herkesten iyi bilir.
        </span>
        </div>
      </div>
    )
  }
}
