import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class Icerik extends Component{
  render(){
    console.log(this.props)
    let icerik = this.props.content?this.props.content : false;
    return(
      <div className="Icerik">
        <h3>{this.props.name}</h3>
        {icerik?icerik.map((value,key)=>(<value.tag key={key}>{value.textarea}</value.tag>)):"İçerik Bulunamadı."}
      </div>
    )
  }
}
