import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class Header extends Component{
  constructor(props){
    super(props);
    console.log(props)
  }
  render(){
    return(
      <ul>
        {this.props.routelist.map((route, i) => (
          <li key={i}>
            <Link key={i} to={route.path}>{route.name}</Link>
          </li>
        ))}
      </ul>
    )
  }
}
