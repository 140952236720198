import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';

import Navigation from './components/navigation'
import Anasayfa from './components/anasayfa'
import Fuar from './components/fuar'
import Iletisim from './components/iletisim'
import Icerik from './components/icerik'
import RouteWithSubRoutes from './components/subroutes'
import Tasiyici from './components/tasiyici'
import Footer from './components/footer'

/*
  Bütün linklerin configi {routes} içinde
  Alt linkler ve içerikler burdan ayarlanıyor
*/
import routes from './configRoute.js'



export default class App extends Component{
  render(){
    return (
      <Router>
        <div className="App">
          <Navigation routelist={routes}/>
          <div className="Icerik-wrapper">
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} route={route} path={route.path}/>
          ))}
          </div>
          <Footer/>
        </div>
      </Router>
    )
  }
}
