import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Slider from '../slider';
import Widget from '../widget';
import Boxes from '../boxes'
import Features from '../features'

export default class Anasayfa extends Component{
  render(){
    return(
      <div>
        <Slider/>
        <Widget/>
        <Boxes/>
        <Features/>
      </div>
    )
  }
}
