import React, {Component} from 'react';
import {BrowserRouter as Router, Route,Link} from 'react-router-dom';
import Header from '../header'
import Logo from '../logo'
import Firmatanim from '../firmatanim'
import Navtel from '../navtel'
import Social from '../social'
export default class Navigation extends Component{

  render(){
    return(
      <nav>
        <Logo/>
        <Firmatanim/>
        <Navtel/>
        <Social/>
        <Header routelist={this.props.routelist}/>
      </nav>
    )
  }
}
