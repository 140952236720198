import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import EmirLogo from '../../emircargologo.png'


export default class Logo extends Component{
  render(){
    return(
      <div className="Logo">
      <div className="sarikutu"></div>
        <img src={EmirLogo}/>
      </div>
    )
  }
}
