import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class Fuar extends Component{
  constructor(props){
    super(props)
    this.state = {
          takvim: [
            {
              fuar_ad:"Utrecht Turizm Fuarı",
              metrekare: 652,
              ulke_ad: "Hollanda",
              sehir:"Utrecht",
              tarih:"09 - 13 Ocak 2019",
              musavirlik:"Lahey Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"ITB Berlin Turizm Fuarı",
              metrekare: 2000,
              ulke_ad: "Almanya",
              sehir:"Berlin",
              tarih:"06 - 10 Mart 2019",
              musavirlik:"Berlin Kültür Müşavirliği"
            },
            {
              fuar_ad:"Arabian Travel Market (ATM)",
              metrekare: 800,
              ulke_ad: "B.A.E.",
              sehir:"Dubai",
              tarih:"28 Nisan - 01 Mayıs 2019",
              musavirlik:"Dubai Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"World Travel Market (WTM) Londra Fuarı",
              metrekare: 767,
              ulke_ad: "İngiltere",
              sehir:"Londra",
              tarih:"04 - 06 Kasım 2019",
              musavirlik:"Londra Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Ferienmesse Wien",
              metrekare: 100,
              ulke_ad: "Avusturya",
              sehir:"Viyana",
              tarih:"10 - 13 Ocak 2019",
              musavirlik:"Viyana Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Satte Yeni Delhi",
              metrekare: 250,
              ulke_ad: "Hindistan",
              sehir:"Yenidelhi",
              tarih:"15 - 17 Ocak 2019",
              musavirlik:"Yeni Delhi Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Matka Uluslararasi Turizm ve Seyahat Fuarı",
              metrekare: 120,
              ulke_ad: "Finlandiya",
              sehir:"Helsinki",
              tarih:"17 - 20 Ocak 2019",
              musavirlik:"Helsinki Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Boot Dusseldorf",
              metrekare: 250+80+50,
              ulke_ad: "Almanya",
              sehir:"Dusseldorf",
              tarih:"19 - 27 Ocak 2019",
              musavirlik:"Frankfurt Kültür Ataşeliği"
            },
            {
              fuar_ad:"FITUR Turizm Fuarı",
              metrekare: 400,
              ulke_ad: "Ispanya",
              sehir:"Madrid",
              tarih:"23 - 27 Ocak 2019",
              musavirlik:"Madrid Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"New York Times Travel Show",
              metrekare: 100,
              ulke_ad: "A.B.D.",
              sehir:"New York	",
              tarih:"25 - 27 Ocak 2019",
              musavirlik:"New York Kültür ve Tanıtma Ataşeliği"
            }
            ,
            {
              fuar_ad:"Ferienmesse Zürih (FESPO)",
              metrekare: 150,
              ulke_ad: "İsviçre",
              sehir:"Zürih",
              tarih:"25 - 27 Ocak 2019",
              musavirlik:"Zürih Kültür ve Tanıtma Ataşeliği"
            },
            {
              fuar_ad:"Int. Tourist Exhibition Holiday & SPA EXPO",
              metrekare: 150,
              ulke_ad: "Bulgaristan",
              sehir:"Sofya",
              tarih:"14 - 16 Şubat 2019",
              musavirlik:"Sofya Kültür ve Tanıtma Müşavirliği"
            }
            ,
            {
              fuar_ad:"ITFA Belgrad Uluslararasi Turizm Fuarı",
              metrekare: 150,
              ulke_ad: "Sirbistan",
              sehir:"Belgrad",
              tarih:"21 - 24 Şubat 2019",
              musavirlik:"Belgrad Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Tahran Otelcilik ve Yan Ekipmanlari Seyahat ve Turizm Fuarı",
              metrekare: 100,
              ulke_ad: "İran",
              sehir:"Tahran",
              tarih:"12 - 15 Şubat 2019",
              musavirlik:"Tahran Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"MITT Moskova",
              metrekare: 300,
              ulke_ad: "Rusya",
              sehir:"Moskova",
              tarih:"12 - 14 Mart 2019",
              musavirlik:"Moskova Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"UITT Ukrayna Uluslararası Tur.ve Sey.Fuarı",
              metrekare: 300,
              ulke_ad: "Ukrayna",
              sehir:"Kiev",
              tarih:"27 - 29 Mart 2019",
              musavirlik:"Kiev Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Matta Fair-1 2019",
              metrekare: 90,
              ulke_ad: "Malezya",
              sehir:"Kuala Lumpur",
              tarih:"15 - 17 Mart 2019",
              musavirlik:"Kuala Lumpur Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Azerbeycan Ulus.Tur.ve Sey. F. (AITF)",
              metrekare: 150,
              ulke_ad: "Azerbeycan",
              sehir:"Bakü",
              tarih:"04 - 06 Nisan 2019",
              musavirlik:"Bakü Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Sea Trade Cruise Global",
              metrekare: 135,
              ulke_ad: "A.B.D.",
              sehir:"Fort Lauderdale",
              tarih:"08 - 11 Nisan 2019",
              musavirlik:"Vaşington Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Cottm China Outbound Travel and Tourism Market",
              metrekare: 140,
              ulke_ad: "Çin",
              sehir:"Pekin",
              tarih:"15 - 17 Nisan 2019",
              musavirlik:"Pekin Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"Kazakistan U.arasi Tur.Fuarı (KITF)",
              metrekare: 100,
              ulke_ad: "Kazakistan",
              sehir:"Almatı",
              tarih:"17 - 19 Nisan 2019",
              musavirlik:"Astana Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"IMEX Frankfurt",
              metrekare: 250,
              ulke_ad: "Almanya",
              sehir:"Frankfurt",
              tarih:"21 - 23 Mayıs 2019",
              musavirlik:"Frankfurt Kültür Ataşeliği"
            },
            {
              fuar_ad:"Tourism Expo Japan",
              metrekare: 100,
              ulke_ad: "Japonya",
              sehir:"Osaka",
              tarih:"24 - 27 Ekim 2019",
              musavirlik:"Tokyo Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"TT Warsaw 2019",
              metrekare: 150,
              ulke_ad: "Polonya",
              sehir:"Varşova",
              tarih:"21 - 23 Kasım 2019",
              musavirlik:"Varşova Kültür ve Tanıtma Müşavirliği"
            },
            {
              fuar_ad:"IGTM Uluslararasi Golf Fuarı",
              metrekare: "",
              ulke_ad: "",
              sehir:"",
              tarih:"",
              musavirlik:""
            }
          ]
        }

  }

  render(){

    return(
      <div className="Icerik-content">
        <h2>Fuar</h2>
        <div className="Fuar-wrapper">
          <div className="fuar-table">
            <div className="table-header">
              <span>No</span>
              <span>Fuarın Adı</span>
              <span>M2</span>
              <span>Ülke Adı</span>
              <span>Şehir Adı</span>
              <span>Fuar Tarihi</span>
              <span>Müşavirlik / Ateşelik</span>
            </div>
            {
              this.state.takvim.map((deger,i)=>{
                return (
                  <div className="Row">
                    <span>{i+1}</span>
                    <span>{deger.fuar_ad}</span>
                    <span>{deger.metrekare}</span>
                    <span>{deger.ulke_ad}</span>
                    <span>{deger.sehir}</span>
                    <span>{deger.tarih}</span>
                    <span>{deger.musavirlik}</span>
                  </div>)
              })
            }
          </div>
        </div>

      </div>
    )
  }
}
