import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import RouteWithSubRoutes from '../subroutes'
import Navigation from '../navigation'
import Header from '../header'

export default class Tasiyici extends Component{
  preview(gelen,link,routes){
    if(gelen == "/"+link.toLowerCase()){
      console.log(gelen);
      return <img src="https://scontent.fbtz1-3.fna.fbcdn.net/v/t1.0-9/45722938_307255253207481_1844912341687205888_n.jpg?_nc_cat=107&_nc_ht=scontent.fbtz1-3.fna&oh=858b69456cd79b11e8b4cb4127c30824&oe=5C6C6257"/>
    }else{
      console.log(link.toLowerCase());

      return routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} route={route} path={route.path}/>
      ))
    }
  }
  render(){
    return(
      <div className="Icerik-Content">
        <h2>{this.props.name}</h2>
        <Header routelist={this.props.routes}/>

        {this.preview(this.props.location.pathname,this.props.name,this.props.routes)}
        {/*this.props.routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} route={route} path={route.path}/>
        ))*/}
      </div>
    )
  }
}
