import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class Firmatanim extends Component{
  render(){
    return(
      <div className="Firmatanim-wrapper">
      <span>ULUSLARARASI
      <br/>FUAR LOJİSTİĞİ</span>
      </div>
    )
  }
}
