import React, { Component } from 'react';
import Navigation from './components/navigation'
import Anasayfa from './components/anasayfa'
import Fuar from './components/fuar'
import Referanslarimiz from './components/referanslarimiz'
import Iletisim from './components/iletisim'
import Icerik from './components/icerik'
import RouteWithSubRoutes from './components/subroutes'
import Tasiyici from './components/tasiyici'


const routes = [
  {
    path: "/",
    exact:true,
    name: "Ana Sayfa",
    component: Anasayfa
  },

  {
    path: "/kurumsal",
    exact:false,
    name: "Kurumsal",
    component: Tasiyici,
    routes: [
      {
        path: "/kurumsal/vizyonumuz",
        exact:false,
        name: "Vizyonumuz",
        content:[
          {
            tag:'p',
            textarea:"Lojistik ve taşımacılık denilince,akla ilk gelen, dünya standartlarında bir marka olmak."
          }
        ],
        component: Icerik
      },
      {
        path: "/kurumsal/misyonumuz",
        exact:false,
        name: "Misyonumuz",
        content:[
          {
            tag:'p',
            textarea:"Güvenilir, süreklilik, liderlik ve saygınlık vazgeçilmeyen ilkelerimizdir.Müşterilerimiz için yeni değerler yaratmak, evrensel kalite ve standartlarında lojistik hizmetleri sunmak, ekip arkadaşlarımızla beraber ülkemize ve sektörümüze değer kazandıracak iş modelleri geliştirmek var oluş nedenimizdir."
          }
        ],
        component: Icerik
      },
      {
        path: "/kurumsal/hizmetanlayisimiz",
        exact:false,
        name: "Hizmet Anlayışımız",
        content:[
          {
            tag:'p',
            textarea:"Emir Cargo' da ihtiyaçlarınız 'proje yaklaşımı' ve 'tasarım' anlayışı ile karşılanır."
          },
          {
            tag:'p',
            textarea:"Lojistik alanında sunduğu hizmet kalitesi sayesinde Emir Cargo, geleneksel lojistik anlayışını bir adım öteye taşımıştır.Emir Cargo, her zaman, hizmette farklılık yaratmanın özenli bir 'tasarım' gerektirdiğini bilerek çalışır."
          },
          {
            tag:'p',
            textarea:'Verdiği tüm hizmetlerde kalite güvencesi sunan Emir Cargo, etkili çözümler sunmak amacıyla "proje" anlayışını hayata geçirmiştir.Müşteri ihtiyaçlarının karşılanması amacıyla oluşturulan "Emir Cargo Proje Ekipleri" konunun uzmanı danışmanların da desteği ile verilecek hizmetin tüm aşamalarını titizlikle ele alarak, müşteriye özel çözümler tasarlar.Doğru ve detaylı bilgi uzmanlıkla birleştirilerek, teknolojinin sağladığı tüm olanakların desteğinde kaliteli hizmet haline getirilir.'
          }
        ],
        component: Icerik
      },
      {
        path: "/kurumsal/ilkelerimiz",
        exact:false,
        name: "İlkelerimiz",
        content:[
          {
            tag:'li',
            textarea:"Değişimi ve gelişimi birlikte yönetiriz."
          },
          {
            tag:'li',
            textarea:"Verimliliği sürekli sorgulayarak üretkenliğimizi arttırırız."
          },
          {
            tag:'li',
            textarea:"Verdiğimiz sözlere bağlı kalmanın en büyük kazanç olduğunu biliriz."
          },
          {
            tag:'li',
            textarea:"Müşteri ve çalışan bağlılığını ön planda tutarız."
          },
          {
            tag:'li',
            textarea:"Kendimizi müşterinin yerine koyar, gereksinimlerini önceden belirleriz."
          },
          {
            tag:'li',
            textarea:"Müşterilerimiz ve iş ortaklarımıza katma değer yaratırız."
          },
          {
            tag:'li',
            textarea:"Kaliteyi bir yaşam biçimi olarak benimseyip, yayılmasını sağlarız."
          },
        ],
        component: Icerik
      },
      {
        path: "/kurumsal/degerlerimiz",
        exact:false,
        name: "Değerlerimiz",
        content:[
          {
            tag:'p',
            textarea:"Emir Cargo;"
          },
          {
            tag:'li',
            textarea:"Bilginin hızlı ve doğru bir şekilde müşterileri, iş ortakları ve çalışanları ile paylaşılmasını temel ilke olarak benimsemiştir."
          },
          {
            tag:'li',
            textarea:"Soruna değil çözüme çözümlerin kalıcı ve sürekli olmasına odaklanmıştır."
          },
          {
            tag:'li',
            textarea:"Kazandığı deneyimi ve sektör bilgisini tabana yaymaya ve paylaşmaya önem verir."
          },
          {
            tag:'li',
            textarea:"Teknolojiden en iyi şekilde yararlanmaya, performansı ölçmeye odaklanmıştır."
          },
          {
            tag:'li',
            textarea:"Sürekli gelişimi ve verimliliği arttırmayı hedefler."
          },
          {
            tag:'li',
            textarea:"İnsanı ve çevreyi korumayı ve geliştirmeyi faaliyetlerinin ön koşulu olarak taahüt eder."
          },
          {
            tag:'li',
            textarea:"Emir çalışanları, yürüttükleri tüm projelerde şirketin k-İsaç (kalite-İşçi sağlığı, emniyet, çevre) performansından sorumludurlar.Tüm iş planları; ölçülebilir kalite, sağlık, güvenlik hedeflerini içermektedir."
          },
        ],
        component: Icerik
      }
    ]
  },
  {
    path: "/hizmetlerimiz",
    exact:false,
    name: "Hizmetlerimiz",
    component: Tasiyici,
    routes: [
      {
        path: "/hizmetlerimiz/fuarlojistigi",
        exact:false,
        name:'Fuar Lojistiği',
        content:[
          {
            tag:'p',
            textarea:(<b>Uzmanlığımız fuar lojistiği;</b>)
          },

            {
              tag:'p',
              textarea:"Uzun yılların deneyimi ve uzman insan kaynağı ile fuar lojistiğinin normal lojistikten daha farklı olması ve geniş hizmetleri bir araya getirmesi nedeniyle daha yoğun hizmet, bilgi, deneyim ve dikkat gerektirdiğinin bilinciyle hizmet veren Emir Cargo'nun sektördeki faaliyetleri ağırlıklı olarak 'FUAR LOJİSTİĞİ' üzerinde yoğunlaşmıştır."
            },
            {
            tag:'p',
            textarea:(<b>Hizmet, Yüksek kalitede, Dünyanın her yerine!</b>)
          },
          {
            tag:'p',
            textarea:'Hizmette yüksek kalite felsefesini belirleyen Emir Cargo, geniş ve profesyonel yurtdışı acenta ağı ile dünyanın her yerine fuar lojistiği hizmeti vermektedir.Geniş acenta ağımız sayesinde dünyanın neresindeolursa olsun, gerek organizatörler gerekse fuar alanı işletmecileriyle iletişime geçerek; eşyanızın sizden teslim alınması, nakliyesi, standınıza teslimi ve fuar bitimi size iadesi; eşyanız daha deponuzdan çıkmadan önce Emir Cargo profesyonellerince planlanır ve koordine edilir.'
          },
        ],
        component: Icerik
      },
      {
        path: "/hizmetlerimiz/karayolutasimaciligi",
        exact:false,
        name:'Karayolu Taşımacılığı',
        content:[
          {
            tag:'p',
            textarea:"Hızlı, güvenli, hesaplı kara taşımacılığı Emir Cargo'nun uzmanlık alanıdır.Emir Cargo müşterileri için her zaman daha fazla yükü, daha uygun fiyata en hızlı şekilde ulaştırmanın olanaklarını araştırıp geliştirir."
          },
          {
            tag:'p',
            textarea:"Kara taşımacılığı kullandığı araç ve ekipmanları, gelişen teknolojiye uygun olarak zamanında yeniler.Ürünlerinizin güvenli ve hızlı bir şekilde raflardaki yerini alması veya alıcısına ulaşması için projeler geliştirir ve uygular."
          },
          {
            tag:'p',
            textarea:'Her türlü ağırlıktaki veya ölçekteki yük için gerektiğinde; kara, deniz, demiryolu taşıma şekilleri arasında kombinasyonlar yapar ve varış noktasına en güvenli biçimde teslim eder.'
          },
          {
            tag:'p',
            textarea:'Emir Cargo yükün bulunduğu veya üretildiği yerden alınıp araca yüklenmesinden, yükün indirileceği yada teslim edileceği adrese kadar olan tüm detayları tek tek belirlerve müşterisini, internet ve faks aracılığı ile otomatik olarak bilgilendirir.'
          },
          {
            tag:'p',
            textarea:'Emir Cargo, gümrükleme hizmetlerinden sonra, ürünlerin üreticiden nihai tüketiciye kadar merkezive bölgesel dağıtımlarını yapar."Just in Time" teslimatları gerçekleştirir; arta kalan veya geri dönen ürünlerin lojistiğini de projelendirir.'
          }
        ],
        component: Icerik
      },
      {
        path: "/hizmetlerimiz/kargomuhendisligi",
        exact:false,
        name:'Kargo Mühendisliği',
        content:[
          {
            tag:'p',
            textarea:"Emir Cargo'nun taşımacılık mühendisliği ve lojistik deneyimi; coğrafi konum ne kadar zor, proje ne kadar karmaşık olursa olsun, her türlü çözüm olanağını üretebilecek düzeydedir."
          },
          {
            tag:'p',
            textarea:"Özel proje gerektiren taşımalarınızda Emir Cargo;"
          },
          {
            tag:'li',
            textarea:'Fizibilite, planlama ve uygulama hazırlığı'
          },
          {
            tag:'li',
            textarea:'Yol incelemesi'
          },
          {
            tag:'li',
            textarea:'Ekipman ve uygunluk incelemesi'
          },
          {
            tag:'li',
            textarea:'Ülke kanun ve kurallar çerçevesinde araştırma'
          },
          {
            tag:'li',
            textarea:'Alternatif çözüm önerileri sunumu'
          },
          {
            tag:'li',
            textarea:'Risklerin baştan tesbiti ve sıfırlanması'
          },
          {
            tag:'li',
            textarea:'Garantili paketleme, depolama, taşıma ve teslimat'
          },
          {
            tag:'li',
            textarea:'Fiyat kalite dengesinde hizmet sunmaktadır.'
          },
          {
            tag:'p',
            textarea:'Emir Cargo , sınırsız güven mottosu ile yol incelemesi ve başta ekipman olmak üzere her türlü uygunluk araştırması ve çözüme ilişkin belirlemeleri yapar, fizibiliteyi hazırlar, alternatifli olarak onayınıza sunar.Proje kapsamındaki her ülkenin kanun ve kurallarını öğrenir; dikkatine alır.Yerel değerleri ve yasaların uygulanışı saptayarak, olası riskleri baştan ortadan kaldırır ve uygun maliyetli çözümleri sunar.'
          },
          {
            tag:'p',
            textarea:'Emir Cargo, özel taşımalarda fizibilite hazırlar, planlama ve yönetimi gerçekleştirir ve projenin gerektiğini tüm lojistik hizmetleri; paketleme, depolama, depo yönetimi dahil olmak üzere verir.'
          },
          {
            tag:'p',
            textarea:'Emir Cargo, özel taşımalarda fizibilite hazırlar, planlama ve yönetimi gerçekleştirir ve projenin gerektiğini tüm lojistik hizmetleri; paketleme, depolama, depo yönetimi dahil olmak üzere verir.'
          },
          {
            tag:'p',
            textarea:'Dökme yük ve çok büyük kargo taşımalarında gerektiğinde kara, hava, deniz ve demiryolu taşıması kombinasyonu uygular.Dünyanın her noktasında gümrükleme hizmeti verir.Ulusal ve uluslararası fuar lojistiği konusunda bir marka haline gelmiş olan Emir Cargo; dünyanın önde gelen acentalarıyla yapılan anlaşmalar doğrultusunda, ithalat ve ihracatta, sınırsız güven ile hızlı ve emniyetli yük taşımacılığı gerçekleştirmektedir.'
          },
          {
            tag:'p',
            textarea:'Dökme yük ve çok büyük kargo taşımalarında gerektiğinde kara, hava, deniz ve demiryolu taşıması kombinasyonu uygular.Dünyanın her noktasında gümrükleme hizmeti verir.Ulusal ve uluslararası fuar lojistiği konusunda bir marka haline gelmiş olan Emir Cargo; dünyanın önde gelen acentalarıyla yapılan anlaşmalar doğrultusunda, ithalat ve ihracatta, sınırsız güven ile hızlı ve emniyetli yük taşımacılığı gerçekleştirmektedir.'
          },
        ],
        component: Icerik
      },
      {
        path: "/hizmetlerimiz/havayolutasimaciligi",
        exact:false,
        name:'Havayolu Taşımacılığı',
        content:[
          {
            tag:'p',
            textarea:"Dünyanın her yerinden havalimanı veya kapı teslimi havayolu taşımacılığı hizmeti veren Emir Cargo, seçkin havayolu şirketleriyle yaptığı anlaşmalar sayesinde müşterilerine yer ve fiyat avantajı sağlamaktadır."
          },
          {
            tag:'p',
            textarea:"İhracatta artan dış rekabetin ve uluslararası arenadaki alıcıların daha hızlı termin isteklerinin sonucu olarak, hizmetin kalitesi kadar ekonomik olması da önem kazanmıştır.Tüm yatırımını tamamlamış olan Emir Cargo, müşterilerinin her türlü talebini karşılayacak kapasitededir.Amerika, Uzakdoğu ve Avrupa'nın birçok yerinden konsoline servis olanağı da sağlayan Emir Cargo, askılı konfeksiyon taşımacılığının yanı sıra müşterilerine depoda ofis, kalite kontrol imkanı, gümrükleme ve lojistik hizmetlerinide sunmaktadır."
          },
          {
            tag:'p',
            textarea:(<b>Havayolu Ürün ve Hizmetler</b>)
          },
          {
            tag:'li',
            textarea:"Havalimanından havalimanına teslim,"
          },
          {
            tag:'li',
            textarea:"Havalimanından kapıya teslim,"
          },
          {
            tag:'li',
            textarea:"Kapıdan havaalanına teslim,"
          },
          {
            tag:'li',
            textarea:"Gümrükleme dahil kapıya teslim,"
          },
          {
            tag:'li',
            textarea:"Kombine taşımacılık(Deniz + Hava,Tır + Hava)"
          },
        ],
        component: Icerik
      },
      {
        path: "/hizmetlerimiz/gumruklemehizmetleri",
        exact:false,
        name:'Gümrükleme Hizmetleri',
        content:[
          {
            tag:'p',
            textarea:"Emir Cargo, tedarik zincirinin en kritik adımlarından biri olan 'gümrükleme' hizmetini Emir Cargo kalitesi ile müşterilerine sunmaktadır.Diğer ülkelere kıyasla daha katı kurallara sahip ülkemizde gümrük uygulama ve mevzuatları sık sık güncellenmektedir. Bu nedenle de yüksek düzeyde uzmanlık gerektirmektedir."
          },
          {
            tag:'p',
            textarea:"Emir Cargo, kanunlara uygunluk ve hız konusundaki iddiasını deneyimli ekibi ile destekliyor.Her müşterisinin sektörüne, mevzuatına, operasyonel ihtiyaçlarına uygun özel çözümler üretiyor ve müşterisiyle sanki ona ait bir gümrükleme departmanıymış gibi senkronize çalışıyor.Konu ile ilgili her detayı müşterisinden önce düşünüp çözüm üreterek, müşterisinin sadece kendi işine konsantre olmasına olanak sağlıyor."
          },
        ],
        component: Icerik
      },
      {
        path: "/hizmetlerimiz/anahtarteslim",
        exact:false,
        name:'Anahtar Teslim Yük ve Taşımacılık Yönetimi',
        content:[
          {
            tag:'p',
            textarea:"Yük ve Taşımacılık Yöntemi, 'En baştaki tedarikçiden en son müşteriye kadar ki tüm süreçte bilgi ve ürün yönetimi' şeklinde tanımlanabilir."
          },
          {
            tag:'p',
            textarea:"Tedarikçinizin tedarikçisini yada müşterinizin müşterisini de içine alacak şekilde genişletilebilecek bir zincirin, müşterilerimize bir rekabet avantajı sağlayacakken verimli ve en düşük maliyetle yönetilmesi amacıyla;"
          },
          {
            tag:'p',
            textarea:"Planlanması, gerçekleştirilmesi, kontrol edilmesi tarafımızca gerçekleştirilir.Anahtar Teslim Yük Ve Taşımacılık Yönetimi aşağıdaki hizmet alanlarından oluşmaktadır;"
          },
          {
            tag:'li',
            textarea:"Ürün Tedariği"
          },
          {
            tag:'li',
            textarea:"Satın Alma Yönetimi ve Optimizasyonu"
          },
          {
            tag:'li',
            textarea:"Müşteri Adına Sipariş Takibi Yönetimi"
          },
          {
            tag:'li',
            textarea:"Stok Yönetimi ve Raporlaması"
          },
          {
            tag:'li',
            textarea:"Üretim ve Operasyon Planlamaları Çerçevesinde Malzeme Hareket Yönetimi"
          },
          {
            tag:'li',
            textarea:"Lojistik Asistan Hizmetleri (Ürün kalite kontrol, paketleme, etiketleme, barkodlama...)"
          },
          {
            tag:'li',
            textarea:"Ulusal ve Uluslararası Mal ve ürün Dağıtımı"
          },
          {
            tag:'p',
            textarea:"Emir Cargo, ürünlerinizin yerinde imalatından, satın alma yönetimine ve ürünleriniz için gerekli olan doğru malzemenin, doğru miktarlarda, doğru zamanda, doğru yerde ve en az maliyetle bulundurulması için, ihtiyacınız olan her türlü desteği, bu konudaki deneyimli kadrosunu, bilgi birikimini ve teknik olanaklarını kullanarak sunmaktadır. İhtiyaçları bir mühendis titizliği ile ele alarak 'müşteriye özgü' çözümler üretmektedir."
          },
        ],
        component: Icerik
      },
    ]
  },
  {
    path: "/fuar",
    exact:false,
    name: "Fuar Takvimi",
    component: Fuar
  },
  {
    path: "/referanslarimiz",
    exact:false,
    name: "Referanslarımız",
    component: Referanslarimiz
  },
  {
    path: "/iletisim",
    exact:false,
    name: "İletişim",
    component: Iletisim
  },
  {
    path: "/blog",
    exact:false,
    name: "Blog",
    component: Tasiyici,
    routes: [
      {
        path: "/blog/1",
        exact:false,
        name: "Haber 1",
        component: Icerik
      },
      {
        path: "/blog/2",
        exact:false,
        name: "Haber 2",
        component: Icerik
      }
    ]
  },
];
export default routes;
