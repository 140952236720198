import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Animated_Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import 'normalize.css/normalize.css';
import '../../slider-animations.css';

const content = [
	{
		title: '',
		description:
		'',
		button: '',
		image: "https://scontent.fbtz1-5.fna.fbcdn.net/v/t1.0-9/39239632_272094086723598_2055178689070497792_n.png?_nc_cat=105&_nc_ht=scontent.fbtz1-5.fna&oh=4617636e75a71781d1839b854d51361f&oe=5C83B718",
		//user: 'Luan Gjokaj',
		//userProfile: 'https://i.imgur.com/JSW6mEk.png'
	},
	{
		title: '',
		description:
		'',
		button: '',
		image: 'https://scontent.fbtz1-4.fna.fbcdn.net/v/t1.0-9/45227940_305848913348115_2664287600914726912_n.jpg?_nc_cat=106&_nc_ht=scontent.fbtz1-4.fna&oh=b78bd97adbeca9d3519d14f09da9d19c&oe=5C77BDA5',

	}
];


export default class Slider extends Component{
  render(){
    return(
      <div className="Slider-wrapper">
      <Animated_Slider autoplay={3000} className="slider-wrapper">
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{ background: `url('${item.image}') no-repeat center center` }}
          >
            <div className="inner">
              <h1>{item.title}</h1>
              <p>{item.description}</p>
              {/*<button>{item.button}</button>*/}
            </div>

          </div>
        ))}
      </Animated_Slider>

      </div>
    )
  }
}
