import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class Referanslarimiz extends Component{
  render(){
    return(
      <div className="Icerik-Content">
        <h2>Referanslarımız</h2>
        <div className="Referans-wrapper">
          <ul>
            <li>Spice Hotel & Spa Belek</li>
            <li>Gloria Hotels & Resorts Belek</li>
            <li>Maxx Royal Resorts</li>
            <li>Limak Lara De Luxe Hotel & Resort</li>
            <li>Letoonia Golf Resort Belek</li>
            <li>IC Hotels Green Palace</li>
            <li>Baia Lara Hotel</li>
            <li>Ela Quality Resort Belek Hotel</li>
            <li>Mirage Park Resort Kemer</li>
            <li>Hotel Grand Park Lara</li>
            <li>DoubleTree by Hilton Kemer</li>
            <li>Granada Luxury Belek</li>
            <li>Aska Lara Resort & Spa</li>
            <li>Barut Hotels</li>
            <li>Regnum Carya Golf & Spa Resort</li>
            <li>Susesi Luxury Resort</li>
            <li>Cornelia De Luxe Golf Resort</li>
          </ul>
          <ul>
            <li>Crystal Hotels</li>
            <li>Titanic Beach Hotel Lara</li>
            <li>Voyage Belek Golf & Spa Belek</li>
            <li>Rixos Downtown Antalya</li>
            <li>Kempinski Hotel The Dome Belek</li>
            <li>Paloma Hotels</li>
            <li>Sirene Luxury Hotel Bodrum</li>
            <li>Güral Premier Hotels & Resorts</li>
            <li>Alva Donna Exclusive Hotel & Spa</li>
            <li>The Land Of Legends Theme Park</li>
            <li>Kemer Belediyesi</li>
            <li>Mastob - Manavgat Side Turistik Otelciler Birliği Derneği</li>
            <li>Matso - Manavgat Ticaret ve Sanayi Odası</li>
            <li>Altid - Alanya Turistik İşletmeciler Derneği</li>
            <li>Boytav - Bodrum Yarımadası Tanıtma Vakfı</li>
            <li>Stone Group Hotels</li>
          </ul>
        </div>
      </div>
    )
  }
}
